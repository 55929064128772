import api from "../httpClient.js";

export async function fetchEventosStats() {
  const response = await api.get("/eventosdashboard");
  return response.data;
}

export async function fetchEventoHistorico() {
  const response = await api.get("/eventosdashboard");
  return response.data;
}

export async function fetchEventoCustos() {
  const response = await api.get("/eventoscustosdashboard");
  return response.data.custos;
}

export async function fetchEventosCustomedio(cliente_id) {
  const response = await api.get(`/eventos-historico?cliente=${cliente_id}`);
  return response.data.eventos;
}

export async function getEventos(filtros) {
  const response = await api.get(`/eventos${filtros}`);
  return response.data.eventos;
}

export async function getEvento(id) {
  const response = await api.get(`/eventos/${id}`);
  return response.data.evento[0];
}

export async function putEvento(id, evento) {
  let body = {};
  for (let key in evento) {
    if (
      key != "cliente" &&
      key != "cor" &&
      key != "classificacao" &&
      key != "tipoevento" &&
      key != "origem" &&
      key != "clientecelular" &&
      key != "clientetelefone" &&
      key != "cidade" &&
      key != "icone" &&
      key != "exige_veiculo" &&
      key != "exige_estoque" &&
      key != "exige_veiculo" &&
      key != "op" &&
      key != "adm_id" &&
      key != "login_radius"
    ) {
      body[key] = evento[key];
    }
  }
  return api.put(`/eventos/${id}`, body);
}

export async function putEventoTags(id, tags) {
  return api.put(`/eventos/${id}`, {
    tags: tags,
  });
}

export async function postEvento(evento) {
  let body = {};
  for (let key in evento) {
    body[key] = evento[key];
  }
  return api.post("/eventos/add", body);
}

export function putCancelarEvento(id) {
  return api.put(`/eventos/${id}/cancelar`);
}

export function putConcluirEvento(id) {
  return api.put(`/eventos/${id}/concluir`);
}

export async function getEventosPorClassificacao(filtros) {
  const response = await api.get(
    `/eventos-classificacoes${filtros ? filtros : ""}`
  );
  return response.data.eventos;
}

export async function getEventosPorTipos(filtros) {
  const response = await api.get(`/eventos-tipos${filtros ? filtros : ""}`);
  return response.data.eventos;
}
