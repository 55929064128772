const routes = [
  {
    path: "/sistema/tocs",
    name: "Tocs",
    component: () => import("@/views/sistema/tocs/Tocs.vue"),
    meta: {
      menu: "sistema",
      title: "Configurações",
      breadcrumb: [{ text: "pages.home", to: { name: "Home" } }],
    },
  },
  {
    path: "/sistema/tocs/empresa/:empresaId",
    name: "Empresa",
    component: () => import("@/views/sistema/tocs/Empresa.vue"),
    props: true,
    meta: {
      menu: "sistema",
      title: "Empresa",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "dynamic_crumb" },
      ],
      voltar: { name: "Tocs" },
    },
  },
  {
    path: "/sistema/tocs/adicionar",
    name: "AddEmpresa",
    component: () => import("@/views/sistema/tocs/AddEmpresa.vue"),
    meta: {
      menu: "sistema",
      title: "Adicionar Empresa",
      breadcrumb: [{ text: "pages.home", to: { name: "Home" } }],
      voltar: { name: "Tocs" },
    },
  },
];

export default routes;
