const routes = [
  {
    path: "/sistema/usuarios",
    name: "Usuarios",
    component: () => import("@/views/sistema/usuarios/Usuarios.vue"),
    meta: {
      menu: "sistema",
      title: "Usuarios",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.usuario", plural: true },
      ],
    },
  },
  {
    path: "/sistema/usuario/:usuarioId",
    name: "Usuario",
    component: () => import("@/views/sistema/usuarios/Usuario.vue"),
    props: true,
    meta: {
      menu: "sistema",
      title: "Usuario",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.usuario", plural: true, to: { name: "Usuarios" } },
        { text: "dynamic_crumb" },
      ],
      voltar: { name: "Usuarios" },
    },
  },
  {
    path: "/sistema/usuarios/adicionar",
    name: "NovoUsuario",
    component: () => import("@/views/sistema/usuarios/NovoUsuario.vue"),
    meta: {
      menu: "sistema",
      title: "Adicionar Usuario",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.usuario", plural: true, to: { name: "Usuarios" } },
        { text: "pages.adicionarNovo" },
      ],
      voltar: { name: "Usuarios" },
    },
  },
];

export default routes;
