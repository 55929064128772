const routes = [
  {
    path: "/relatorios",
    redirect: { name: "RelatorioListaBloqueio" },
    meta: {
      menu: "relatorios",
      title: "Relatorios",
      breadcrumb: [{ text: "pages.home", to: { name: "Home" } }],
    },
  },
  {
    path: "/relatorios/cobranca/lista-bloqueio",
    name: "RelatorioListaBloqueio",
    component: () => import("@/views/relatorios/cobranca/ListaBloqueio.vue"),
    meta: {
      menu: "relatorios",
      title: "Lista de Bloqueio",
      breadcrumb: [{ text: "pages.home", to: { name: "Home" } }],
    },
  },
  {
    path: "/relatorios/cobranca/lista-atrasados",
    name: "RelatorioListaAtrasados",
    component: () => import("@/views/relatorios/cobranca/ListaAtrasados.vue"),
    meta: {
      menu: "relatorios",
      title: "Lista de Atrasados",
      breadcrumb: [{ text: "pages.home", to: { name: "Home" } }],
    },
  },
  {
    path: "/relatorios/cobranca/lista-vencidos-dia",
    name: "RelatorioListaVencidosDia",
    component: () => import("@/views/relatorios/cobranca/ListaVencidosDia.vue"),
    meta: {
      menu: "relatorios",
      title: "Lista de Vencidos do Dia",
      breadcrumb: [{ text: "pages.home", to: { name: "Home" } }],
    },
  },
];

export default routes;
