const routes = [
  {
    path: "/configuracoes/origens",
    name: "Origens",
    component: () => import("@/views/configuracoes/origens/Origens.vue"),
    meta: {
      menu: "configuracoes",
      title: "Origens",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.origem", plural: true },
      ],
    },
  },
];

export default routes;
