const routes = [
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/dashboard/home/Home.vue"),
    meta: {
      menu: "dashboard",
      title: "Home",
    },
  },
];

export default routes;
