import api from "../httpClient.js";

export async function getUsuarios() {
  const response = await api.get("/usuarios");
  return response.data.users;
}

export async function getUsuario(id) {
  const response = await api.get(`/usuarios/${id}`);
  return response.data.user;
}

export async function putUsuario(id, usuario) {
  let body = {};
  for (let key in usuario) {
    body[key] = usuario[key];
  }
  return api.put(`/usuarios/${id}`, body);
}

export async function postUsuario(usuario) {
  let body = {};
  for (let key in usuario) {
    body[key] = usuario[key];
  }
  return api.post("/usuarios/add", body);
}

// User Classificacoes
export async function getUserClassificacoes(id) {
  const response = await api.get(`/usuarios/${id}/classificacoes`);
  return response.data.userclassificacoes;
}

// User Depositos
export async function fetchUserDepositos(filtros) {
  const response = await api.get(`/usersdepositos${filtros}`);
  return response.data.userdeposito;
}

export async function postUserDeposito(userDeposito) {
  let body = {};
  for (let key in userDeposito) {
    body[key] = userDeposito[key];
  }
  return api.post("/usersdepositos/add", body);
}

export async function fetchDepositosNotEqual(id) {
  const response = await api.get(`/produtosdepositos/${id}/notequal`);
  return response.data.depositos;
}

// Get Images
export async function getImages(id) {
  const response = await api.get(`/usuarios/${id}/imgs`);
  return response.data.user_imgs;
}

// Upload Image
export async function uploadImage(fd) {
  const response = await api.post("/user/uploadImage", fd);
  return response;
}

export function deleteUserClassificacao(id) {
  return api.delete(`/usersclassificacoes/${id}`);
}

export function deleteUserDeposito(id) {
  return api.delete(`/usersdepositos/${id}`);
}
