import Vue from "vue";
import Vuetify from "vuetify/lib";
import pt from "vuetify/es5/locale/pt";
import en from "vuetify/es5/locale/en";
import es from "vuetify/es5/locale/es";

Vue.use(Vuetify);

const locale = localStorage.getItem("locale");
let currentLocale = "";
if (locale) {
  currentLocale = locale;
} else if (navigator.language) {
  currentLocale = navigator.language.substring(0, 2);
}

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#14525E",
        secondary: "#424242",
        lightgreen: "#14525E",
        lightgray: "#F4F5F7",
        darkblue: "#1F3D73",
        toolbar: "#208c6e",
        white: "#FFFFFF",
        tabs: "#f5f5f5",
        tabs2: "#CFD8DC",
        tabs3: "#CFD8DC",
        anchors: "#F8AE25",
        buttons: "#F8AE25",
        buttons2: "#2196F3",
        accent: "#1fb48a",
        error: "#FF5252",
        info: "#F8AE25",
        success: "#4CAF50",
        usermessage: "#DCF8C6",
        warning: "#FFC107",
        chat: "#F4F5F7",
      },
      dark: {
        primary: "#14525E",
        secondary: "#424242",
        lightgreen: "#c38a22",
        lightgray: "#222222",
        darkblue: "#1F3D73",
        toolbar: "#424242",
        white: "#424242",
        tabs: "#f5f5f5",
        tabs2: "#14525E",
        tabs3: "#CFD8DC",
        anchors: "#F8AE25",
        buttons: "#14525E",
        buttons2: "#2196F3",
        accent: "#F8AE25",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
  lang: {
    locales: { pt, en, es },
    current: currentLocale,
  },
  icons: {
    iconfont: "md",
  },
});
