import { all, update, novo, del } from "@/services/api/tocsconfig.api";
import Vue from "vue";

const initialState = () => ({
  configs: null,
  modulo_whatsapp: null,
  modulo_estoque: null,
});

const state = initialState();

// getters
const getters = {};

// actions
const actions = {
  getConfigs({ commit }) {
    return new Promise((resolve, reject) => {
      all()
        .then((response) => {
          commit("UPDATE_CONFIGS", response);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          reject();
        });
    });
  },
  postConfig({ dispatch }, config) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$Progress.start();
      novo(config)
        .then((response) => {
          if (response.status === 201) {
            Vue.prototype.$toast.success("Campo adicionado com sucesso!");
            dispatch("getConfigs");
            Vue.prototype.$Progress.finish();
            resolve();
          }
        })
        .catch((error) => {
          console.log(error);
          Vue.prototype.$Progress.fail();
          reject();
        });
    });
  },
  putConfig({ dispatch }, { id, config }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$Progress.start();
      update(id, config)
        .then((response) => {
          if (response.status === 201) {
            Vue.prototype.$toast.success("Campo atualizado com sucesso!");
            dispatch("getConfigs");
            Vue.prototype.$Progress.finish();
            resolve();
          }
        })
        .catch((error) => {
          console.log(error);
          Vue.prototype.$Progress.fail();
          reject();
        });
    });
  },
  delConfig({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$Progress.start();
      del(id)
        .then((response) => {
          if (response.status === 200) {
            Vue.prototype.$toast.success("Campo deletado com sucesso!");
            dispatch("getConfigs");
            Vue.prototype.$Progress.finish();
            resolve();
          }
        })
        .catch((error) => {
          console.log(error);
          Vue.prototype.$Progress.fail();
          reject();
        });
    });
  },
  getModulos({ commit }) {
    return new Promise((resolve, reject) => {
      all("?grupo=modulos")
        .then((response) => {
          response.forEach((item) => {
            if (item.campo === "modulo_whatsapp") {
              commit("UPDATE_MODULOWHATS", item.valor);
            }
            if (item.campo === "modulo_estoque") {
              commit("UPDATE_MODULOESTOQUE", item.valor);
            }
          });
          resolve();
        })
        .catch((error) => {
          console.error(error);
          reject();
        });
    });
  },
  reset({ commit }) {
    commit("RESET");
  },
};

// mutations
const mutations = {
  UPDATE_MODULOWHATS(state, payload) {
    state.modulo_whatsapp = payload;
  },
  UPDATE_MODULOESTOQUE(state, payload) {
    state.modulo_estoque = payload;
  },
  UPDATE_CONFIGS(state, payload) {
    state.configs = payload;
  },
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
