import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";
import dashboardRoutes from "@/router/routes/dashboard/index.js";
import sistemaRoutes from "@/router/routes/sistema/index.js";
import configuracoesRoutes from "@/router/routes/configuracoes/index.js";
import estoqueRoutes from "@/router/routes/estoque/index.js";
import superadminRoutes from "@/router/routes/superadmin/index.js";
import relatoriosRoutes from "@/router/routes/relatorios/index.js";

// let dashRoutes = [
//   ...dashboardRoutes,
//   ...sistemaRoutes,
//   ...configuracoesRoutes,
//   ...superadminRoutes,
// ];

// if (store.state.Tocsconfig.modulo_estoque) {
//   dashRoutes.push(estoqueRoutes);
// }

Vue.use(VueRouter);
const routes = [
  {
    path: "*",
    component: () => import("@/views/PaginaNaoEncontrada.vue"),
  },
  {
    path: "/feedback/:eventoId",
    name: "Feedback",
    props: true,
    component: () => import("@/views/feedback/Feedback.vue"),
    meta: {
      title: "Feedback",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: {
      title: "Login",
    },
  },
  {
    path: "/",
    component: () => import("@/layout/AppLayout.vue"),
    redirect: { name: "Home" },
    meta: {
      login: true,
      breadcrumb: [{ text: "pages.home" }],
    },
    children: [
      ...dashboardRoutes,
      ...sistemaRoutes,
      ...configuracoesRoutes,
      ...estoqueRoutes,
      ...superadminRoutes,
      ...relatoriosRoutes,
    ],
  },
];
// ].concat(allRoutes);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} - TOCS` || "TOCS";
  if (to.matched.some((record) => record.meta.login)) {
    if (!window.localStorage.token) {
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  if (to.name != "Login") {
    window.localStorage.last_route = to.path;
  }
  // if (to.matched.some(record => record.meta.component)) {
  //   let result = false;
  //   let component = null;
  //   component = store.state.Usuario.menus.find(
  //     obj => obj.componente === to.meta.component
  //   );
  //   if (component) {
  //     result = component.status;
  //   }
  //   if (!result) {
  //     router.push({ name: "Home" });
  //   }
  // }
});

export default router;
