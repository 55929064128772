import api from "../httpClient.js";

export default {
  async getOrigens() {
    const response = await api.get("/origens");
    return response.data.origens;
  },

  async getOrigen(id) {
    const response = await api.get(`/origens/${id}`);
    return response.data.origens;
  },

  async putOrigens(id, descricao) {
    return api.put(`/origens/${id}`, {
      descricao
    });
  },

  async postOrigens(descricao) {
    return api.post("/origens/add", {
      descricao
    });
  }
};
