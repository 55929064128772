const routes = [
  {
    path: "/sistema/audits",
    name: "Audits",
    component: () => import("@/views/sistema/audits/Audits.vue"),
    meta: {
      menu: "sistema",
      title: "Audits",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.auditoria", to: { name: "Audits" } },
      ],
      voltar: { name: "Usuarios" },
    },
  },
];

export default routes;
