const routes = [
  {
    path: "/estoque/classificacoes",
    name: "ProdutoClassificacoes",
    component: () =>
      import("@/views/estoque/produtoClassificacoes/ProdutoClassificacoes.vue"),
    meta: {
      menu: "estoque",
      title: "Classificações",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.classificacao", plural: true },
      ],
      voltar: { name: "Produtos" },
    },
  },
];

export default routes;
