const routes = [
  {
    path: "/eventos",
    name: "Eventos",
    component: () => import("@/views/dashboard/eventos/Eventos.vue"),
    meta: {
      menu: "dashboard",
      title: "Eventos",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.evento", plural: true },
      ],
    },
    children: [
      {
        path: "/eventos/:eventoId",
        component: () => import("@/views/dashboard/evento/Evento.vue"),
        props: true,
        redirect: (to) => ({
          path: "/eventos/:eventoId/informacoes",
        }),
        meta: {
          menu: "dashboard",
          title: "Evento",
          dialog: true,
          voltar: { name: "Eventos" },
        },
        children: [
          {
            path: "/eventos/:eventoId/informacoes",
            component: () =>
              import("@/views/dashboard/evento/EventoInformacoes.vue"),
            props: true,
            meta: {
              title: "Informações",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
          {
            path: "/eventos/:eventoId/financeiro",
            component: () =>
              import("@/views/dashboard/evento/EventoFinanceiro.vue"),
            props: true,
            meta: {
              title: "Financeiro",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
          {
            path: "/eventos/:eventoId/historico",
            component: () =>
              import("@/views/dashboard/evento/EventoHistorico.vue"),
            props: true,
            meta: {
              title: "Histórico",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
          {
            path: "/eventos/:eventoId/conexoes",
            component: () =>
              import("@/views/dashboard/evento/EventoConexoes.vue"),
            props: true,
            meta: {
              title: "Conexões",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
          {
            path: "/eventos/:eventoId/notas",
            component: () => import("@/views/dashboard/evento/EventoNotas.vue"),
            props: true,
            meta: {
              title: "Notas",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
          {
            path: "/eventos/:eventoId/materiais",
            component: () =>
              import("@/views/dashboard/evento/EventoMateriais.vue"),
            props: true,
            meta: {
              title: "Materiais",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
          {
            path: "/eventos/:eventoId/tarefas",
            component: () =>
              import("@/views/dashboard/evento/EventoTarefas.vue"),
            props: true,
            meta: {
              title: "Tarefas",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
        ],
      },
    ],
  },
  {
    path: "/evento/adicionar",
    name: "NovoEvento",
    component: () => import("@/views/dashboard/eventos/NovoEvento.vue"),
    meta: {
      menu: "dashboard",
      title: "Adicionar Evento",
      dialog: true,
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.evento", plural: true, to: { name: "Eventos" } },
        { text: "pages.adicionarNovo" },
      ],
      voltar: { name: "Eventos" },
    },
  },
  {
    path: "/evento/:eventoId/editar",
    name: "EventoEditar",
    props: true,
    component: () => import("@/views/dashboard/eventos/EventoEditar.vue"),
    meta: {
      menu: "dashboard",
      title: "Editar Evento",
      dialog: true,
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.evento", plural: true, to: { name: "Eventos" } },
        { text: "dynamic_crumb" },
      ],
      voltar: { name: "Eventos" },
    },
  },
  {
    path: "/evento/:eventoId/concluir",
    name: "EventoBaixa",
    props: true,
    component: () => import("@/views/dashboard/eventos/EventoBaixa.vue"),
    meta: {
      menu: "dashboard",
      title: "Concluir Evento",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.evento", plural: true, to: { name: "Eventos" } },
        { text: "dynamic_crumb" },
      ],
      voltar: { name: "Eventos" },
    },
  },
];

export default routes;
