import ComponentsApi from "@/services/api/validate-components.api.js";

const initialState = () => ({
  componentes: null
});

const state = initialState();

// getters
const getters = {
  getAccess: (state, getters, rootState) => (componente, type) => {
    let result = false;
    let check = 0;
    const admin = rootState.Usuario.usuario.is_admin;
    if (admin) {
      result = true;
    } else {
      if (state.componentes) {
        let comp = state.componentes.find(comp => comp.componente === componente);
        if (comp) {
          for (let key in rootState.bitWiseTable) {
            if (type === key) {
              check = rootState.bitWiseTable[key];
            }
          }
          if (comp.status) {
            result = check & comp.nivel_acesso ? true : false;
          }
        }
      }

    }

    return result;
  }
};

// actions
const actions = {
  getComponentes(context) {
    const id = context.rootState.Usuario.usuario.id;
    return ComponentsApi.getComponentes(id)
      .then(response => {
        context.commit("UPDATE_COMPONENTES", response);
      })
      .catch(error => {
        console.error(error);
      });
  },
  reset({ commit }) {
    commit("RESET");
  }
};

// mutations
const mutations = {
  UPDATE_COMPONENTES(state, payload) {
    state.componentes = payload;
  },
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
