import { getEventos } from "@/services/api/eventos.api.js";
import { getEventosTipos } from "@/services/api/eventos-tipos.api.js";
import { getClassificacoes } from "@/services/api/classificacoes.api.js";
import OrigensApi from "@/services/api/origens.api.js";

const initialState = () => ({
  classificacoes: [],
  eventoTipos: [],
  origens: [],
  status: [
    {
      text: "Cancelado",
      value: -1,
    },
    {
      text: "Pendente",
      value: 0,
    },
    {
      text: "Agendado",
      value: 1,
    },
    {
      text: "Em Andamento",
      value: 2,
    },
    {
      text: "Aguardando Aprovação",
      value: 3,
    },
    {
      text: "Aprovado",
      value: 4,
    },
  ],
  prioridades: [
    {
      text: "Normal",
      value: 0,
    },
    {
      text: "Urgente",
      value: 1,
    },
    {
      text: "Emergencial",
      value: 2,
    },
  ],
});

const state = initialState();

// getters
const getters = {};

// actions
const actions = {
  getClassificacoes({ commit }) {
    getClassificacoes()
      .then((response) => {
        commit("UPDATE_CLASSIFICACOES", response);
      })
      .catch((error) => console.log(error));
  },
  getEventosTipos({ commit }) {
    getEventosTipos()
      .then((response) => {
        commit("UPDATE_EVENTOTIPOS", response);
      })
      .catch((error) => console.log(error));
  },
  getOrigens({ commit }) {
    OrigensApi.getOrigens()
      .then((response) => {
        commit("UPDATE_ORIGENS", response);
      })
      .catch((error) => console.log(error));
  },
  reset({ commit }) {
    commit("RESET");
  },
};

// mutations
const mutations = {
  UPDATE_CLASSIFICACOES(state, payload) {
    state.classificacoes = payload;
  },
  UPDATE_EVENTOTIPOS(state, payload) {
    state.eventoTipos = payload;
  },
  UPDATE_ORIGENS(state, payload) {
    state.origens = payload;
  },
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
