const routes = [
  {
    path: "/superadmin/variaveis",
    name: "Configs",
    component: () => import("@/views/superadmin/tocsconfig/Configs.vue"),
    meta: {
      menu: "superadmin",
      title: "Variáveis",
      breadcrumb: [{ text: "pages.home", to: { name: "Home" } }],
    },
  },
];

export default routes;
