const routes = [
  {
    path: "/configuracoes/veiculos",
    name: "Veiculos",
    component: () => import("@/views/configuracoes/veiculos/Veiculos.vue"),
    meta: {
      menu: "configuracoes",
      title: "Veiculos",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.veiculo", plural: true },
      ],
    },
  },
  {
    path: "/configuracoes/veiculo/:veiculoId",
    props: true,
    name: "Veiculo",
    component: () => import("@/views/configuracoes/veiculos/Veiculo.vue"),
    meta: {
      menu: "configuracoes",
      title: "Veiculo",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.veiculo", plural: true, to: { name: "Veiculos" } },
        { text: "dynamic_crumb" },
      ],
      voltar: { name: "Veiculos" },
    },
  },
  {
    path: "/configuracoes/veiculos/adicionar",
    name: "NovoVeiculo",
    component: () => import("@/views/configuracoes/veiculos/NovoVeiculo.vue"),
    meta: {
      menu: "configuracoes",
      title: "Adicionar Veiculo",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.veiculo", plural: true, to: { name: "Veiculos" } },
      ],
      voltar: { name: "Veiculos" },
    },
  },
];

export default routes;
