import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import * as filters from "./filters"; // global filters

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
Vue.use(VueToast, {
  position: "top-right",
});

import VueLodash from "vue-lodash";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
Vue.use(VueLodash, { lodash: { cloneDeep, isEqual, isEmpty, orderBy } });

const moment = require("moment");
require("moment/locale/pt");
Vue.use(require("vue-moment"), {
  moment,
});

// import VueEcho from "vue-echo";
// import Echo from "laravel-echo";
// window.Pusher = require("pusher-js");
// const EchoInstance = new Echo({
//   broadcaster: "pusher",
//   key: "SOFTPAR",
//   wsHost: "192.168.1.37",
//   wsPort: 6001,
//   disableStats: true,
// });
// Vue.use(VueEcho, EchoInstance);

import VueProgressBar from "vue-progressbar";
Vue.use(VueProgressBar, {
  color: "#75e289",
  failedColor: "#d64242",
  thickness: "4px",
});

Vue.config.productionTip = false;

Vue.component("PageToolbar", () =>
  import("@/components/global/PageToolbar.vue")
);
Vue.component("NaoAutorizado", () =>
  import("@/components/global/NaoAutorizado.vue")
);
Vue.component("Carregando", () => import("@/components/global/Carregando.vue"));
Vue.component("AlertBar", () => import("@/components/global/AlertBar.vue"));
Vue.component("NotFound", () => import("@/components/global/NotFound.vue"));
Vue.component("NaoEncontrado", () =>
  import("@/components/global/NaoEncontrado.vue")
);
Vue.component("BreadCrumbs", () =>
  import("@/components/global/BreadCrumbs.vue")
);

// register global utility filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
