<template>
  <div id="app">
    <vue-progress-bar></vue-progress-bar>
    <transition mode="out-in">
      <router-view v-if="!loading" />
    </transition>
  </div>
</template>

<script>
import { api } from "@/services/httpClient.js";

export default {
  name: "App",
  data: () => ({
    loading: true,
  }),
  mounted() {
    if (window.location.pathname.split("/")[1] != "feedback") {
      let locale = "";
      if (localStorage.getItem("locale")) {
        locale = localStorage.getItem("locale");
      } else {
        locale = "es";
      }

      if (window.localStorage.token) {
        this.$store
          .dispatch("Usuario/getUsuario")
          .then(() => {
            this.$store.dispatch("Componentes/getComponentes").then(() => {
              import(
                /* webpackChunkName: "locale-[request]" */ `@/locales/${locale}.json`
              )
                .then((msgs) => {
                  this.$root.$i18n.setLocaleMessage(locale, msgs.default);
                  this.$root.$i18n.locale = locale;
                  this.loading = false;
                  this.$store.dispatch("Tocsconfig/getModulos");
                })
                .finally(() => {
                  this.$Progress.finish();
                  this.loading = false;
                });
            });
            this.$store.dispatch("Usuario/getUsuarios");
          })
          .catch(() => {
            window.localStorage.removeItem("token");
            this.$router.push({ name: "Login" });
            this.loading = false;
            this.$Progress.fail();
          });
      } else {
        if (this.$route.name != "Login") {
          this.$router.push({ name: "Login" });
        }
        this.loading = false;
        this.$Progress.finish();
      }

      const template = localStorage.getItem("template");
      if (template) {
        if (template === "light") {
          this.$vuetify.theme.dark = false;
          this.$store.commit("UPDATE_TEMPLATE", { dark: false, light: true });
        } else {
          this.$vuetify.theme.dark = true;
          this.$store.commit("UPDATE_TEMPLATE", { dark: true, light: false });
        }
      } else {
        localStorage.setItem("template", "light");
      }
    } else {
      this.$Progress.finish();
      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
.toast-text {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
}

.v-navigation-drawer__content::-webkit-scrollbar {
  display: none;
}

.theme--dark.v-sheet {
  color: #eeeeee !important;
}

.theme--dark.v-icon {
  color: #eeeeee !important;
}

.panel-gradient-bg {
  background: rgb(18, 74, 81);
  background: linear-gradient(
    90deg,
    rgba(18, 74, 81, 1) 0%,
    rgba(20, 82, 94, 1) 50%,
    rgba(18, 74, 81, 1) 100%
  );
}

.panel-gradient-bg-dark {
  background: rgb(15, 15, 15);
  background: linear-gradient(
    90deg,
    rgba(15, 15, 15, 1) 0%,
    rgba(33, 33, 33, 1) 50%,
    rgba(15, 15, 15, 1) 100%
  );
}

.pattern-bg {
  background-color: #eeeeee !important;
  // background-image: url("./assets/pattern.svg") !important;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: 100%;
}

.pattern-bg-dark {
  background-color: #3a3a3a !important;
  // background-image: url("./assets/pattern_dark.svg") !important;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: 100%;
}

.data-tables__row-click {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}

.v-enter,
.v-leave-to {
  transform: translate3d(-30px, 0, 0);
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.8s;
}

.v-input input {
  height: 32px !important;
}

.v-overflow-btn .v-input__slot {
  border-top: none !important;
  border-width: 1px 0 !important;
  border-color: rgba(0, 0, 0, 0.42) !important;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: rgba(0, 0, 0, 0.12) !important;
}

.v-btn--outlined {
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.v-data-table-header-mobile {
  display: none;
}

.v-data-table td,
.v-data-table th {
  padding: 0 6px !important;
}

.v-data-table {
  th {
    font-size: 0.7rem !important;
    height: 32px !important;
    text-transform: uppercase;
  }
  td {
    font-size: 0.68rem !important;
    height: 32px !important;
  }
  .v-data-table__mobile-table-row {
    display: flex !important;
    max-width: 100%;
    flex-wrap: wrap;
    margin-bottom: 15px !important;
    border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
    padding: 10px 0;
    // border-radius: 4px;

    &:last-child {
      border-bottom: none !important;
      margin-bottom: 0px;
    }
  }

  .v-data-table__mobile-row {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 0px !important;
    margin-bottom: 10px;

    // .v-data-table__mobile-row__header {
    //   display: none;
    // }

    .v-data-table__mobile-row__cell {
      font-size: 12px;
      width: 100%;
      text-align: left;
    }

    &:last-child {
      border-bottom: none !important;
      margin-bottom: 0px;
    }
  }
}

.v-text-field input,
.v-textarea textarea {
  font-size: 14px;
}
</style>
