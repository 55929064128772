import api from "../httpClient.js";

export async function getClassificacoes() {
  const response = await api.get("/classificacoes");
  return response.data.classificacoes;
}

export async function getClassificacao(id) {
  const response = await api.get(`/classificacoes/${id}`);
  return response.data.classificacao;
}

export async function getClassificacoesNotEqual(id) {
  const response = await api.get(`/classificacoes/${id}/notequal`);
  return response.data.classificacoes;
}

export function putClassificacoes(id, classificacao) {
  let body = {};
  for (let key in classificacao) {
    body[key] = classificacao[key];
  }
  return api.put(`/classificacoes/${id}`, body);
}

export function postClassificacoes(classificacao) {
  let body = {};
  for (let key in classificacao) {
    body[key] = classificacao[key];
  }
  return api.post("/classificacoes/add", body);
}
