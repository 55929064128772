const routes = [
  {
    path: "/configuracoes/tags",
    name: "Tags",
    props: true,
    component: () => import("@/views/configuracoes/tags/Tags.vue"),
    meta: {
      menu: "configuracoes",
      title: "Tags",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.tags" },
      ],
      voltar: { name: "Home" },
    },
  },
];

export default routes;
