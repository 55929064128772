import api from "../httpClient.js";

export async function getAllEventosprodutos(filtros) {
  const response = await api.get(`/eventosprodutos${filtros}`);
  return response.data.eventosprodutos;
}

export async function getOneEventosprodutos(id) {
  const response = await api.put(`/eventosprodutos/${id}`);
  return response.data.produtos;
}

export async function deleteEventosprodutos(id) {
  return api.delete(`/eventosprodutos/${id}`);
}

export async function putEventosprodutos(id, produtos) {
  let body = {};
  for (let key in produtos) {
    body[key] = produtos[key];
  }
  return api.put(`/eventosprodutos/${id}`, body);
}
export async function postEventosprodutos(produto) {
  let body = {};
  for (let key in produto) {
    body[key] = produto[key];
  }
  return api.post("/eventosprodutos/add", body);
}

// Get Produtos Depositos
export async function fetchProdutosDepositos(id) {
  const response = await api.get(`/produtosdepositos/${id}/produtos`);
  return response.data.produtosdepositos;
}
