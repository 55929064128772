import Vue from "vue";
import router from "./../router";
import axios from "axios";
import store from "@/store/index.js";
import i18n from "@/plugins/i18n";

const url = process.env.VUE_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: url + "/api",
});

axiosInstance.interceptors.request.use(
  function(config) {
    const token = window.localStorage.token;
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// interceptor to catch errors
const errorInterceptor = (error) => {
  // all the error responses
  if (!error.response) {
    Vue.prototype.$toast.error(i18n.tc("errors.semConexao"));
  } else {
    switch (error.response.status) {
      case 400:
        Vue.prototype.$toast.error(i18n.tc("errors.error400"));
        break;
      case 401: // authentication error, logout the user
        Vue.prototype.$toast.error(i18n.tc("errors.error401"));
        if (store.state.Usuario.auto_redirect === false) {
          store.commit("Usuario/UPDATE_AUTO_REDIRECT", true);
        }
        if (store.state.Usuario.login === true) {
          store.commit("Usuario/UPDATE_LOGIN", false);
        }

        if (router.currentRoute.name != "Login") {
          router.push({ name: "Login" });
        }

        break;
      case 422:
        break;
      case 404: {
        break;
      }
      case 409: {
        break;
      }

      default:
        Vue.prototype.$toast.error(i18n.tc("errors.errorGeral"));
    }
  }

  return Promise.reject(error);
};

axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor);

// Interceptor for responses
const responseInterceptor = (response) => {
  switch (response.status) {
    case 200:
      // yay!
      break;
    default:
    // default case
  }

  return response;
};

export const api = {
  get(endpoint) {
    return axiosInstance.get(endpoint);
  },
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body);
  },
  put(endpoint, body) {
    return axiosInstance.put(endpoint, body);
  },
  delete(endpoint) {
    return axiosInstance.delete(endpoint);
  },
  validateToken() {
    return axiosInstance.get(url + "/api/profile");
  },
};

export default api;
