import Vue from "vue";
import Vuex from "vuex";

// import the auto exporter
import modules from "./modules";

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  strict: process.env.NODE_ENV !== "production",
  state: {
    itemsPerPage: 10,
    dynamic_crumb: null,
    majorError: null,
    expiredSection: null,
    bitWiseTable: {
      visualizar: 1,
      adicionar: 2,
      editar: 4,
      deletar: 8,
      imprimir: 16,
      exportar: 32,
      limitar: 64,
      menu: 128,
    },
    locale: "",
    template: {
      dark: false,
      light: true,
    },
    query: null,
    imagemSelecionada: null,
  },
  mutations: {
    UPDATE_SELECTEDIMAGE(state, payload) {
      state.imagemSelecionada = payload;
    },
    UPDATE_QUERY(state, payload) {
      state.query = payload;
    },
    UPDATE_TEMPLATE(state, payload) {
      state.template = payload;
    },
    UPDATE_ITEMSPERPAGE(state, payload) {
      state.itemsPerPage = payload;
    },
    UPDATE_DYNAMICCRUMB(state, payload) {
      state.dynamic_crumb = payload;
    },
    UPDATE_LOCALE(state, payload) {
      state.locale = payload;
    },
  },
  actions: {},
});
