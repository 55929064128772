const routes = [
  {
    path: "/sistema/permissoes",
    name: "Permissoes",
    component: () => import("@/views/sistema/permissoes/Permissoes.vue"),
    meta: {
      menu: "sistema",
      title: "Permissões",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        {
          text: "pages.permissao",
          plural: true,
          to: { name: "Permissoes" },
        },
      ],
      voltar: { name: "Usuarios" },
    },
  },
  {
    path: "/sistema/permissao/:permissaoId",
    name: "Permissao",
    component: () => import("@/views/sistema/permissoes/Permissao.vue"),
    props: true,
    meta: {
      menu: "sistema",
      title: "Permissão",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        {
          text: "pages.permissao",
          plural: true,
          to: { name: "Permissoes" },
        },
        { text: "dynamic_crumb" },
      ],
      voltar: { name: "Permissoes" },
    },
  },
  {
    path: "/sistema/permissoes/adicionar",
    name: "NovaPermissao",
    component: () => import("@/views/sistema/permissoes/NovaPermissao.vue"),
    meta: {
      menu: "sistema",
      title: "Adicionar Permissão",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        {
          text: "pages.permissao",
          plural: true,
          to: { name: "Permissoes" },
        },
        { text: "pages.adicionarNovo" },
      ],
      voltar: { name: "Permissoes" },
    },
  },
];

export default routes;
