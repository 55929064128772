const routes = [
  {
    path: "/configuracoes/classificacoes",
    name: "Classificacoes",
    component: () =>
      import("@/views/configuracoes/classificacoes/Classificacoes.vue"),
    meta: {
      menu: "configuracoes",
      title: "Classificações",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.classificacao", plural: true },
      ],
    },
  },
  {
    path: "/configuracoes/classificacoes/:classificacaoId",
    props: true,
    name: "Classificacao",
    component: () =>
      import("@/views/configuracoes/classificacoes/Classificacao.vue"),
    meta: {
      menu: "configuracoes",
      title: "Classificacao",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        {
          text: "pages.classificacao",
          plural: true,
          to: { name: "Classificacoes" },
        },
        { text: "dynamic_crumb" },
      ],
      voltar: { name: "Classificacoes" },
    },
  },
  {
    path: "/configuracoes/classificacoes/:classificacaoId/tipos/:eventoTipoId",
    props: true,
    name: "EventoTipo",
    component: () =>
      import("@/views/configuracoes/eventostipos/EventoTipo.vue"),
    meta: {
      menu: "configuracoes",
      title: "Tipo de Evento",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        {
          text: "pages.classificacao",
          plural: true,
          to: { name: "Classificacoes" },
        },
        { text: "dynamic_crumb" },
      ],
    },
  },
];

export default routes;
