import api from "../httpClient.js";

export async function all(filtro) {
  if (filtro) {
    const response = await api.get(`/tocsconfig${filtro}`);
    return response.data.configs;
  } else {
    const response = await api.get("/tocsconfig");
    return response.data.configs;
  }
}
export async function one(id) {
  const response = await api.put(`/tocsconfig/${id}`);
  return response.data.config;
}
export function update(id, tocsConfig) {
  let body = {};
  for (let key in tocsConfig) {
    body[key] = tocsConfig[key];
  }
  return api.put(`/tocsconfig/${id}`, body);
}
export function novo(tocsConfig) {
  let body = {};
  for (let key in tocsConfig) {
    body[key] = tocsConfig[key];
  }
  return api.post("/tocsconfig/add", body);
}

export async function del(id) {
  return api.delete(`/tocsconfig/${id}`);
}
