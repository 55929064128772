const routes = [
  {
    path: "/atendimentos",
    name: "PainelMeusAtendimentos",
    component: () => import("@/views/dashboard/meusatendimentos/Painel.vue"),
    meta: {
      menu: "none",
      title: "Atendimentos",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.meusatendimentos" },
      ],
    },
    children: [
      {
        path: "/atendimentos/:eventoId",
        component: () => import("@/views/dashboard/evento/Evento.vue"),
        props: true,
        redirect: (to) => ({
          path: "/atendimentos/:eventoId/informacoes",
        }),
        meta: {
          menu: "none",
          title: "Evento",
          dialog: true,
          voltar: { name: "Eventos" },
        },
        children: [
          {
            path: "/atendimentos/:eventoId/informacoes",
            component: () =>
              import("@/views/dashboard/evento/EventoInformacoes.vue"),
            props: true,
            meta: {
              title: "Informações",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
          {
            path: "/atendimentos/:eventoId/financeiro",
            component: () =>
              import("@/views/dashboard/evento/EventoFinanceiro.vue"),
            props: true,
            meta: {
              title: "Financeiro",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
          {
            path: "/atendimentos/:eventoId/historico",
            component: () =>
              import("@/views/dashboard/evento/EventoHistorico.vue"),
            props: true,
            meta: {
              title: "Histórico",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
          {
            path: "/atendimentos/:eventoId/conexoes",
            component: () =>
              import("@/views/dashboard/evento/EventoConexoes.vue"),
            props: true,
            meta: {
              title: "Conexões",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
          {
            path: "/atendimentos/:eventoId/notas",
            component: () => import("@/views/dashboard/evento/EventoNotas.vue"),
            props: true,
            meta: {
              title: "Notas",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
          {
            path: "/atendimentos/:eventoId/materiais",
            component: () =>
              import("@/views/dashboard/evento/EventoMateriais.vue"),
            props: true,
            meta: {
              title: "Materiais",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
          {
            path: "/atendimentos/:eventoId/tarefas",
            component: () =>
              import("@/views/dashboard/evento/EventoTarefas.vue"),
            props: true,
            meta: {
              title: "Tarefas",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
        ],
      },
    ],
  },
];

export default routes;
