import LoginApi from "@/services/api/login.api.js";
import ProfileApi from "@/services/api/profile.api.js";
import { getUsuarios } from "@/services/api/usuarios.api.js";

const initialState = () => ({
  auto_redirect: false,
  login: false,
  usuario: {
    created_at: "",
    id: "",
    name: "",
    email: "",
    is_admin: false,
    super_admin: false,
    updated_at: "",
  },
  usuarios: null,
});

const state = initialState();

// getters
const getters = {};

// actions
const actions = {
  getUsuario({ commit }) {
    return new Promise((resolve, reject) => {
      ProfileApi.getProfile()
        .then((response) => {
          commit("UPDATE_USUARIO", response.user);
          commit("UPDATE_LOGIN", true);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          reject();
        });
    });
  },
  getUsuarios({ commit }) {
    return getUsuarios()
      .then((response) => {
        commit("UPDATE_USUARIOS", response);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  logarUsuario(context, payload) {
    return new Promise((resolve, reject) => {
      LoginApi.postLogin({
        email: payload.email,
        password: payload.senha,
      })
        .then((response) => {
          window.localStorage.token = `Bearer ${response.token}`;
          resolve();
        })
        .catch((error) => {
          console.error(error);
          reject();
        });
    });
  },
  deslogarUsuario(context) {
    context.commit("RESET");
    window.localStorage.removeItem("token");
    context.commit("UPDATE_LOGIN", false);
  },
  reset({ commit }) {
    commit("RESET");
  },
};

// mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  UPDATE_LOGIN(state, payload) {
    state.login = payload;
  },
  UPDATE_AUTO_REDIRECT(state, payload) {
    state.auto_redirect = payload;
  },
  UPDATE_USUARIO(state, payload) {
    state.usuario = Object.assign(state.usuario, payload);
  },
  UPDATE_USUARIOS(state, payload) {
    state.usuarios = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
