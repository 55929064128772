const routes = [
  {
    path: "/estoque/depositos",
    name: "Depositos",
    component: () => import("@/views/estoque/depositos/Depositos.vue"),
    meta: {
      menu: "estoque",
      title: "Depositos",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.depositos" },
      ],
    },
  },
];

export default routes;
