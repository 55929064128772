const routes = [
  {
    path: "/painel",
    name: "PainelEventos",
    component: () => import("@/views/dashboard/paineleventos/Painel.vue"),
    meta: {
      menu: "none",
      title: "Painel",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.paineleventos" },
      ],
    },
    children: [
      {
        path: "/painel/:eventoId",
        component: () => import("@/views/dashboard/evento/Evento.vue"),
        props: true,
        redirect: (to) => ({
          path: "/painel/:eventoId/informacoes",
        }),
        meta: {
          menu: "none",
          title: "Evento",
          dialog: true,
          voltar: { name: "Eventos" },
        },
        children: [
          {
            path: "/painel/:eventoId/informacoes",
            component: () =>
              import("@/views/dashboard/evento/EventoInformacoes.vue"),
            props: true,
            meta: {
              title: "Informações",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
          {
            path: "/painel/:eventoId/financeiro",
            component: () =>
              import("@/views/dashboard/evento/EventoFinanceiro.vue"),
            props: true,
            meta: {
              title: "Financeiro",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
          {
            path: "/painel/:eventoId/historico",
            component: () =>
              import("@/views/dashboard/evento/EventoHistorico.vue"),
            props: true,
            meta: {
              title: "Histórico",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
          {
            path: "/painel/:eventoId/conexoes",
            component: () =>
              import("@/views/dashboard/evento/EventoConexoes.vue"),
            props: true,
            meta: {
              title: "Conexões",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
          {
            path: "/painel/:eventoId/notas",
            component: () => import("@/views/dashboard/evento/EventoNotas.vue"),
            props: true,
            meta: {
              title: "Notas",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
          {
            path: "/painel/:eventoId/materiais",
            component: () =>
              import("@/views/dashboard/evento/EventoMateriais.vue"),
            props: true,
            meta: {
              title: "Materiais",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
          {
            path: "/painel/:eventoId/tarefas",
            component: () =>
              import("@/views/dashboard/evento/EventoTarefas.vue"),
            props: true,
            meta: {
              title: "Tarefas",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
        ],
      },
    ],
  },
];

export default routes;
