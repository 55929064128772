import { getEvento, putEvento } from "@/services/api/eventos.api.js";
import { getAllEventosprodutos } from "@/services/api/eventos-produtos.api.js";
import Vue from "vue";

const initialState = () => ({
  evento: null,
  eventosProdutos: [],
});

const state = initialState();

// getters
const getters = {
  eventoTipoId: (state) => {
    return state.evento.tipo_evento_id;
  },
};

// actions
const actions = {
  reset({ commit }) {
    commit("RESET_EVENTO");
  },

  getEvento({ commit }, id) {
    if (!id) {
      console.log("Id do evento nao encontrado!");
      return;
    }
    return new Promise((resolve, reject) => {
      getEvento(id)
        .then((response) => {
          commit("UPDATE_EVENTO", response);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    });
  },
  upEvento({ state, commit }, { id, evento }) {
    if (!id) {
      console.log("Id do evento nao encontrado!");
      return;
    }
    return new Promise((resolve, reject) => {
      putEvento(id, evento)
        .then((response) => {
          if (response.status === 201) {
            Vue.prototype.$Progress.finish();
            resolve();
          }
        })
        .catch((error) => {
          console.log(error);
          Vue.prototype.$Progress.fail();
          reject();
        });
    });
  },
  getEventosProdutos({ commit }, evento_id) {
    return new Promise((resolve, reject) => {
      getAllEventosprodutos(`?evento_id=${evento_id}`)
        .then((response) => {
          commit("UPDATE_EVENTOSPRODUTOS", response);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    });
  },
};

// mutations
const mutations = {
  UPDATE_EVENTO(state, payload) {
    state.evento = payload;
  },
  UPDATE_EVENTOSPRODUTOS(state, payload) {
    state.eventosProdutos = payload;
  },

  RESET_EVENTO(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
