const routes = [
  {
    path: "/notificar-clientes",
    name: "NotificarClientes",
    component: () =>
      import("@/views/dashboard/notificarClientes/NotificarClientes.vue"),
    meta: {
      menu: "dashboard",
      title: "Notificar Clientes",
    },
  },
];

export default routes;
