import api from "../httpClient.js";

export async function getEventosTipos(filtros) {
  let response;
  if (filtros) {
    response = await api.get(`/eventostipos${filtros}`);
  } else {
    response = await api.get(`/eventostipos`);
  }
  return response.data.eventostipos;
}

export async function getEventoTipo(id) {
  const response = await api.get(`/eventostipos/${id}`);
  return response.data.eventotipo;
}

export function putEventoTipo(id, eventotipo) {
  let body = {};
  for (let key in eventotipo) {
    body[key] = eventotipo[key];
  }
  return api.put(`/eventostipos/${id}`, body);
}

export function postEventoTipo(eventotipo) {
  let body = {};
  for (let key in eventotipo) {
    body[key] = eventotipo[key];
  }
  return api.post("/eventostipos/add", body);
}
