const routes = [
  {
    path: "/clientes",
    name: "Clientes",
    component: () => import("@/views/dashboard/clientes/Clientes.vue"),
    meta: {
      menu: "dashboard",
      title: "Clientes",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.cliente", plural: true },
      ],
    },
  },
  {
    path: "/clientes/:clienteId",
    props: true,
    name: "Cliente",
    component: () => import("@/views/dashboard/clientes/Cliente.vue"),
    meta: {
      menu: "dashboard",
      title: "Cliente",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.cliente", plural: true, to: { name: "Clientes" } },
        { text: "dynamic_crumb" },
      ],
    },
    children: [
      {
        path: "/clientes/:clienteId/evento/:eventoId",
        component: () => import("@/views/dashboard/evento/Evento.vue"),
        props: true,
        redirect: (to) => ({
          path: "/clientes/:clienteId/evento/:eventoId/informacoes",
        }),
        meta: {
          title: "Evento",
          dialog: true,
          voltar: { name: "Cliente" },
        },
        children: [
          {
            path: "/clientes/:clienteId/evento/:eventoId/informacoes",
            component: () =>
              import("@/views/dashboard/evento/EventoInformacoes.vue"),
            props: true,
            meta: {
              title: "Informações",
              dialog: true,
              voltar: { name: "Cliente" },
            },
          },
          {
            path: "/clientes/:clienteId/evento/:eventoId/financeiro",
            component: () =>
              import("@/views/dashboard/evento/EventoFinanceiro.vue"),
            props: true,
            meta: {
              title: "Financeiro",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
          {
            path: "/clientes/:clienteId/evento/:eventoId/historico",
            component: () =>
              import("@/views/dashboard/evento/EventoHistorico.vue"),
            props: true,
            meta: {
              title: "Histórico",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
          {
            path: "/clientes/:clienteId/evento/:eventoId/conexoes",
            component: () =>
              import("@/views/dashboard/evento/EventoConexoes.vue"),
            props: true,
            meta: {
              title: "Conexões",
              dialog: true,
              voltar: { name: "Eventos" },
            },
          },
          {
            path: "/clientes/:clienteId/evento/:eventoId/notas",
            component: () => import("@/views/dashboard/evento/EventoNotas.vue"),
            props: true,
            meta: {
              title: "Notas",
              dialog: true,
              voltar: { name: "Cliente" },
            },
          },
          {
            path: "/clientes/:clienteId/evento/:eventoId/materiais",
            component: () =>
              import("@/views/dashboard/evento/EventoMateriais.vue"),
            props: true,
            meta: {
              title: "Materiais",
              dialog: true,
              voltar: { name: "Cliente" },
            },
          },
          {
            path: "/clientes/:clienteId/evento/:eventoId/tarefas",
            component: () =>
              import("@/views/dashboard/evento/EventoTarefas.vue"),
            props: true,
            meta: {
              title: "Tarefas",
              dialog: true,
              voltar: { name: "Cliente" },
            },
          },
        ],
      },
    ],
  },
  {
    path: "/cliente/adicionar",
    name: "NovoCliente",
    component: () => import("@/views/dashboard/clientes/NovoCliente.vue"),
    meta: {
      menu: "dashboard",
      title: "Adicionar Cliente",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.cliente", plural: true, to: { name: "Clientes" } },
        { text: "pages.adicionarNovo" },
      ],
      voltar: { name: "Clientes" },
    },
  },
];

export default routes;
