import api from "../httpClient.js";

export default {
  // Busca todos os clientes paginado
  async postLogin(usuario) {
    const response = await api.post("/login", {
      email: usuario.email,
      password: usuario.password
    });
    return response.data;
  }
};
