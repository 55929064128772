const routes = [
  {
    path: "/produtos",
    name: "Produtos",
    component: () => import("@/views/estoque/produtos/Produtos.vue"),
    meta: {
      menu: "estoque",
      title: "Produtos",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.produtos" },
      ],
      voltar: { name: "Estoque" },
    },
  },
  {
    path: "/produtos/:produtoId",
    props: true,
    name: "Produto",
    component: () => import("@/views/estoque/produtos/Produto.vue"),
    meta: {
      menu: "estoque",
      title: "Produto",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.produtos", to: { name: "Produtos" } },
        { text: "dynamic_crumb" },
      ],
      voltar: { name: "Produtos" },
    },
  },
  {
    path: "/produto/add",
    props: true,
    name: "NovoProduto",
    component: () => import("@/views/estoque/produtos/NovoProduto.vue"),
    meta: {
      menu: "estoque",
      title: "Adicionar Produto",
      breadcrumb: [
        { text: "pages.home", to: { name: "Home" } },
        { text: "pages.produtos", to: { name: "Produtos" } },
        { text: "pages.adicionarNovo" },
      ],
      voltar: { name: "Produtos" },
    },
  },
];

export default routes;
